import React from 'react'
import styled from 'styled-components'
import { Container, Grid } from '@material-ui/core'
import { rem } from '../../../utils'
import { HomeProps, StyledAltBgSection } from '../index'
import { nonNull } from '../../../utils/nonNull'
import { RelatedArticles } from './relatedArticles'
import { Offers } from './offers'
import { FullScreenCta } from './fullScreenCta'
import { Contained } from '../../../components/contained/contained'
import { ColumnFeature } from './columnFeature'
import { News } from './news'

const StyledFullScreenCtaContainer = styled(Grid)`
  margin-top: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => rem(83, props.theme)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => rem(157, props.theme)};
  }
`

const StyledCardCarouselContainer = styled(Container)`
  padding: 0;
  margin-top: ${props => props.theme.typography.pxToRem(93)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: ${props => props.theme.typography.pxToRem(154)};
  }
`

export const BelowTheFold: React.FC<HomeProps> = ({ entry, entries }) => {
  // We know this is always a homepage, but technically GraphQL says this could be any entry, so we must first check
  if (!entry || entry.__typename !== 'homePage_homePage_Entry') {
    return null
  }

  return (
    <>
      {entry.relatedArticles && (
        <StyledCardCarouselContainer maxWidth={'lg'}>
          <RelatedArticles data={nonNull(entry.relatedArticles)}  heading={entry.featuredContentCarouselHeading??''} />
        </StyledCardCarouselContainer>
      )}
      <StyledAltBgSection maxWidth={false} className="home-promotion">
        {entry.offersPromotions && (
          <Container>
            <Grid item lg={12}>
              <Offers data={nonNull(entry.offersPromotions)} heading={entry.offersPromotionsCarouselHeading??''} />
            </Grid>
          </Container>
        )}
        {entry.fullScreenCta && (
          <>
            <StyledFullScreenCtaContainer>
              <FullScreenCta data={nonNull(entry.fullScreenCta)} />
            </StyledFullScreenCtaContainer>
          </>
        )}
        {entry.columnFeature && (
          <Container>
            <Contained container>
              <Grid item>
                <ColumnFeature data={nonNull(entry.columnFeature)} />
              </Grid>
            </Contained>
          </Container>
        )}
      </StyledAltBgSection>
      {entries && (
        <StyledCardCarouselContainer maxWidth={'lg'}>
          <News entries={nonNull(entries)} />
        </StyledCardCarouselContainer>
      )}
    </>
  )
}